import React from 'react';
import styles from './AutomatedProduction.module.scss';
import OrderInfo from '../OrderInfo/OrderInfo';
import Footer from '../Footer/Footer';

import DSC01053 from '../../images/DSC01053.jpg';
import LINE_ALBUM_220222 from '../../images/LINE_ALBUM_220222.jpg';

import _00_100_I型 from '../../images/_001-00-產線圖-I型(有箭頭).png';
import _00_100_U型 from '../../images/_001-00-產線圖-U型(有箭頭).png';
import _00_100_Y型 from '../../images/_001-00-產線圖-Y型(有箭頭).png';
import _00_100_Z型 from '../../images/_001-00-產線圖-Z型(有箭頭).png';

import { Carousel, Button, Collapse, Modal } from 'antd';
import { CarouselRef } from 'antd/lib/carousel';

const { Panel } = Collapse;
const Fade = require('react-reveal/Fade');

const AutomatedProduction = () => {
  const [orderVisible, setOrderVisible] = React.useState(false);
  const settings = {
    customPaging: (i: number) => {
      return <div></div>;
    },
    fade: true,
    dots: { className: '' },
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: false,
    autoplay: false,
  };

  const carouselContent = [
    { title: '產線圖-I型', value: 0, src: _00_100_I型 },
    { title: '產線圖-U型', value: 1, src: _00_100_U型 },
    { title: '產線圖-Y型', value: 2, src: _00_100_Y型 },
    { title: '產線圖-Z型', value: 3, src: _00_100_Z型 },
  ];

  const carouselRef: any = React.useRef<CarouselRef>(null);
  return (
    <div
      className={`${styles.AutomatedProduction} ${styles.snapContainer}`}
      data-testid="AutomatedProduction"
    >
      <Modal
        title=""
        centered
        visible={orderVisible}
        onOk={() => setOrderVisible(false)}
        onCancel={() => setOrderVisible(false)}
        width={500}
        footer={null}
      >
        <OrderInfo />
      </Modal>
      <div className={`flex justify-center items-center ${styles.snapSection}`}>
        <img
          className="w-fit max-h-[100vh]"
          src="/images/_001-00-5-100.webp"
          alt=""
        />
      </div>
      <div className={`w-full grid grid-cols-4 ${styles.snapSection}`}>
        <Fade bottom>
          <div className="grid col-span-4 h-[30vh] order-last lg:col-span-1 lg:h-full lg:order-first pt-2 sm:pt-8 lg:pt-28 px-4 sm:pb-8">
            <div className="text-left">
              <h1 className="text-2xl sm:text-3xl font-bold">雙向送料手臂</h1>
              <p className="text-base sm:text-xl">
                全新設計的送料手臂,分別 為水平與垂直夾,可以依靠
                更大的開口和高精度的傳感 器系統來實現自動化的送料
                方式(可以搭配手機APP操 控)。
              </p>
            </div>
            <div className="justify-self-end self-end">
              <p
                className="text-xl sm:text-2xl cursor-pointer font-bold"
                onClick={() => setOrderVisible(true)}
              >
                立刻訂購...
              </p>
            </div>
          </div>
        </Fade>
        <div
          className="col-span-4 h-[70vh] lg:col-span-3 lg:h-full"
          style={{
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${DSC01053})`,
          }}
        ></div>
      </div>
      <div className={`w-full grid grid-cols-4 ${styles.snapSection}`}>
        <div
          className="col-span-4 h-[70vh] lg:col-span-3 lg:h-full"
          style={{
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(${LINE_ALBUM_220222})`,
          }}
        ></div>
        <Fade bottom>
          <div className="grid col-span-4 h-[30vh] lg:col-span-1 lg:h-full pt-2 sm:pt-8 lg:pt-28 px-4 sm:pb-8">
            <div className="text-left">
              <h1 className="text-2xl sm:text-3xl font-bold">
                自動化橫移料架(入口/出口)
              </h1>
              <p className="text-base sm:text-xl">
                實現自動化搬運材料,搭配手機APP 配對料單及自動搬移,讓人力極少化。
              </p>
            </div>
            <div className="justify-self-end self-end">
              <p
                className="text-xl sm:text-2xl cursor-pointer font-bold"
                onClick={() => setOrderVisible(true)}
              >
                立刻訂購...
              </p>
            </div>
          </div>
        </Fade>
      </div>
      <div className={`w-full grid grid-cols-4 ${styles.snapSection}`}>
        <div className="col-span-4">
          <div className="hidden md:block" style={{ marginTop: '60px' }}>
            <div className="grid grid-cols-4 col-span-4">
              {carouselContent.map((el, index) => {
                return (
                  <Button
                    onClick={() => {
                      if (carouselRef) {
                        carouselRef.current.goTo(el.value);
                      }
                    }}
                    className="col-auto"
                  >
                    {el.title}
                  </Button>
                );
              })}
            </div>
            <Carousel ref={carouselRef} dotPosition="top" {...settings}>
              {carouselContent.map((el, index) => {
                return (
                  <div className="!flex !justify-center">
                    <img src={el.src} alt="" className="max-h-[80vh]" />
                  </div>
                );
              })}
            </Carousel>
          </div>
          <div className="block md:hidden" style={{ marginTop: '60px' }}>
            <Collapse
              defaultActiveKey={['0']}
              expandIconPosition="right"
              accordion
            >
              {carouselContent.map((el, index) => {
                return (
                  <Panel header={el.title} key={index}>
                    <img src={el.src} alt="" />
                  </Panel>
                );
              })}
            </Collapse>
          </div>
        </div>
        <div className="col-span-4">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default AutomatedProduction;
