import React, { FC } from 'react';
import styles from './Footer.module.scss';

import { Link } from 'react-router-dom';

interface FooterProps {}

const Footer: FC<FooterProps> = () => (
  <div className={`${styles.Footer} py-8`} data-testid="Footer">
    <div className="grid justify-items-center">
      <div className="justify-around flex flex-wrap w-full max-w-[500px]">
        <Link to="/" className="text-l">廣達國際機械有限公司 o 2021</Link>
        <Link to="/ContactUs#Form" className="text-l">聯絡我們</Link>
        <Link to="/" className="text-l">工作機會</Link>
        <Link to="/ContactUs#GMap" className="text-l">地點</Link>
      </div>
    </div>
  </div>
);

export default Footer;
