import React from 'react';
import styles from './ContactUs.module.scss';
import Footer from '../Footer/Footer';

import 單LOGO from '../../images/單LOGO.png';

import { Form, Input, Button } from 'antd';

const ContactUs = () => {
  const layout = {
    labelCol: { span: 6, md: { span: 5, offset: 3 } },
    wrapperCol: { span: 16 },
  };
  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: '${label}為必填',
    types: {
      email: '${label}格式不符!',
      number: '${label}格式不符!',
    },
    number: {
      range: '${label}必須介於${min}和${max}之間',
    },
  };
  /* eslint-enable no-template-curly-in-string */
  const onFinish = (values: any) => {
    console.log(values);
  };
  return (
    <div
      className={`${styles.ContactUs} h-screen grid`}
      data-testid="ContactUs"
    >
      <div className="w-full" style={{ marginTop: '60px' }}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1287.9536783404278!2d120.65069743770387!3d24.070420182619294!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34693b55f7e21809%3A0x2729be920e5193d4!2z5buj6YGU5ZyL6Zqb5qmf5qKw5pyJ6ZmQ5YWs5Y-4!5e0!3m2!1sen!2stw!4v1648434706347!5m2!1sen!2stw"
          width="1000"
          height="450"
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          className="w-full"
        ></iframe>
      </div>
      <div className="w-full my-8 flex flex-wrap items-center justify-center">
        <div>
          <img src={單LOGO} className="max-h-[130px]" alt="" />
        </div>
        <div className="text-left ml-8 self-center">
          <h1 className="text-2xl font-bold">廣達國際機械有限公司</h1>
          <p className="text-xl font-bold">
            台中市烏日區溪福路一巷110弄105-9號
          </p>
          <p className="text-xl">04 2335 6118</p>
        </div>
      </div>
      <div className="w-full my-8 flex items-center justify-center">
        <Form
          id="Form"
          name="Form"
          {...layout}
          onFinish={onFinish}
          validateMessages={validateMessages}
          requiredMark={false}
          className="w-1/2 max-w-[600px]"
        >
          <Form.Item
            name={['user', 'name']}
            label="姓名："
            labelAlign="left"
            labelCol={layout.labelCol}
            rules={[{ required: true }]}
          >
            <Input placeholder="輸入姓名" />
          </Form.Item>
          <Form.Item
            name={['user', 'phone']}
            label="電話："
            labelAlign="left"
            labelCol={layout.labelCol}
          >
            <Input placeholder="輸入電話" />
          </Form.Item>
          <Form.Item
            name={['user', 'email']}
            label="信箱："
            labelAlign="left"
            labelCol={layout.labelCol}
            rules={[{ type: 'email' }]}
          >
            <Input placeholder="輸入信箱" />
          </Form.Item>
          <Form.Item
            name={['user', 'context']}
            label="諮詢內容："
            labelAlign="left"
            labelCol={layout.labelCol}
          >
            <Input.TextArea placeholder="輸入內容" />
          </Form.Item>
          <Form.Item
            wrapperCol={{ ...layout.wrapperCol, offset: 5 }}
            labelAlign="left"
            labelCol={layout.labelCol}
          >
            <Button type="primary" htmlType="submit">
              送出
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className="self-end">
        <Footer />
      </div>
    </div>
  );
};

export default ContactUs;
