import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import logo from './logo.svg';
import Header from './components/Header/Header';
import Home from './components/Home/Home';
import Introduction from './components/Introduction/Introduction';
import GD_STD from './components/GD_STD/GD_STD';
import AutomatedProduction from './components/AutomatedProduction/AutomatedProduction';
import QRCodeIronMedal from './components/QRCodeIronMedal/QRCodeIronMedal';
import ERPIntroduction from './components/ERPIntroduction/ERPIntroduction';
import ContactUs from './components/ContactUs/ContactUs';
import './App.css';

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/introduction" element={<Introduction />} />
        <Route path="/GD-STD" element={<GD_STD />} />
        <Route path="/AutomateProduction" element={<AutomatedProduction />} />
        <Route path="/QRCodeIronMedal" element={<QRCodeIronMedal />} />
        <Route path="/ERPIntroduction" element={<ERPIntroduction />} />
        <Route path="/ContactUs" element={<ContactUs />} />
      </Routes>
    </div>
  );
}

export default App;
