import React from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-dom';
import Footer from '../Footer/Footer';

const Fade = require('react-reveal/Fade');

export interface SectionProps {
  model?: string;
  backgroundImg?: string;
  description?: string;
  leftBtn?: string;
  rightBtn?: string;
  leftBtnLink?: string;
  rightBtnLink?: string;
  textPosition?: string;
  titleFontSize?: string;
  titleFontColor?: string;
  descriptionFontSize?: string;
  descriptionFontColor?: string;
  titleFontWeight?: string;
  descriptionFontWeight?: string;
  height?: string;
  downArrow?: boolean;
  footer?: JSX.Element;
  width?: string;
}

const Section = ({
  model,
  backgroundImg,
  description,
  leftBtn,
  rightBtn,
  leftBtnLink = '#',
  rightBtnLink = '#',
  textPosition,
  titleFontSize = 'inherit',
  titleFontColor = 'black',
  descriptionFontSize = 'inherit',
  descriptionFontColor = 'black',
  titleFontWeight = 'inherit',
  descriptionFontWeight = 'inherit',
  height = '100vh',
  downArrow = false,
  footer,
  width = '100%',
}: SectionProps) => {
  return (
    <Wrap
      bgImage={backgroundImg}
      height={height}
      width={width}
      data-testid="Section"
    >
      <Fade bottom>
        {textPosition === 'center' ? (
          <CenterItemText>
            <h1
              style={{
                fontSize: titleFontSize,
                fontWeight: titleFontWeight,
                color: titleFontColor,
              }}
            >
              {model}
            </h1>
            <p
              style={{
                fontSize: descriptionFontSize,
                fontWeight: descriptionFontWeight,
                color: descriptionFontColor,
              }}
            >
              {description}
            </p>
          </CenterItemText>
        ) : textPosition === 'bottom' ? (
          <div className="text-center pt-[70vh] sm:pt-[80vh]">
            <h1
              style={{
                fontSize: titleFontSize,
                fontWeight: titleFontWeight,
                color: titleFontColor,
              }}
            >
              {model}
            </h1>
            <p
              style={{
                fontSize: descriptionFontSize,
                fontWeight: descriptionFontWeight,
                color: descriptionFontColor,
              }}
            >
              {description}
            </p>
          </div>
        ) : (
          <TopItemText>
            <h1
              className="text-5xl"
              style={{
                fontSize: titleFontSize,
                fontWeight: titleFontWeight,
                color: titleFontColor,
              }}
            >
              {model}
            </h1>
            <p
              className="text-xl"
              style={{
                fontSize: descriptionFontSize,
                fontWeight: descriptionFontWeight,
                color: descriptionFontColor,
              }}
            >
              {description}
            </p>
          </TopItemText>
        )}
      </Fade>

      <div>
        <Buttons>
          <Fade bottom>
            <ButtonGroup>
              {leftBtn && (
                <Link to={leftBtnLink}>
                  <LeftButton>{leftBtn}</LeftButton>
                </Link>
              )}
              {rightBtn && (
                <Link to={rightBtnLink}>
                  <RightButton>{rightBtn}</RightButton>
                </Link>
              )}
            </ButtonGroup>
          </Fade>
          {downArrow && (
            <div className="grid justify-items-center">
              <DownArrow src="/images/down-arrow.svg" />
            </div>
          )}
        </Buttons>
        {footer && footer}
      </div>
    </Wrap>
  );
};

export default Section;

type Custom = {
  bgImage?: string;
  height?: string;
  width?: string;
};

const Wrap = styled.div<Custom>`
  width: ${(props) => {
    return props.width;
  }};
  height: ${(props) => {
    return props.height;
  }};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-item: center;
  background-image: ${(props) => {
    return `url("${props.bgImage}")`;
  }};
`;

const TopItemText = styled.div`
  z-idex: -1;
  padding-top: 15vh;
  text-align: center;
`;
const CenterItemText = styled.div`
  z-idex: -1;
  padding-top: 50vh;
  text-align: center;
`;
const BottomItemText = styled.div`
  z-idex: -1;
  padding-top: 80vh;
  text-align: center;
`;
const ButtonGroup = styled.div`
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const LeftButton = styled.div`
  background-color: rgba(23, 26, 32, 0.6);
  height: 40px;
  width: 256px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  opacity: 0.85;
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;
  margin: 8px;
`;

const RightButton = styled(LeftButton)`
  background-color: #fff;
  color: #000;
`;

const DownArrow = styled.img`
  margin-top: 20px;
  opacity: 0.65;
  height: 40px;
  animation: animateDown infinite 1.5s;
  overflow-x: hidden;
`;

const Buttons = styled.div`
  align-items: center;
  text-align: center;
  justify-content: center;
`;
