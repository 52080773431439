import React, { FC } from 'react';
import styles from './ERPIntroduction.module.scss';
import Footer from '../Footer/Footer';

import OrderInfo from '../OrderInfo/OrderInfo';

import ERP掃描 from '../../images/ERP掃描.png';
import 查詢PDF構件圖 from '../../images/查詢PDF構件圖.png';
import 即時監控 from '../../images/即時監控.png';
import 待出貨區列表 from '../../images/待出貨區列表.png';
import 安裝資訊列表 from '../../images/安裝資訊列表.png';
import 地圖 from '../../images/地圖.png';

import unknown from '../../images/unknown.png';
import unknown1 from '../../images/unknown (1).png';
import unknown2 from '../../images/unknown (2).png';
import unknown3 from '../../images/unknown (3).png';
import unknown4 from '../../images/unknown (4).png';
import unknown5 from '../../images/unknown (5).png';

import ERP_1 from '../../images/ERP_1.png';
import ERP_2 from '../../images/ERP_2.png';

import { Row, Col, Modal } from 'antd';

interface ERPIntroductionProps {}

const ERPIntroduction: FC<ERPIntroductionProps> = () => {
  const [orderVisible, setOrderVisible] = React.useState(false);
  return (
    <div
      className={styles.ERPIntroduction + ' mx-4 md:mx-16'}
      data-testid="ERPIntroduction"
    >
      <Modal
        title=""
        centered
        visible={orderVisible}
        onOk={() => setOrderVisible(false)}
        onCancel={() => setOrderVisible(false)}
        width={500}
        footer={null}
      >
        <OrderInfo />
      </Modal>
      <h1 className="text-4xl sm:text-6xl lg:text-8xl text-zinc-500 font-bold">
        鋼結構導入大數據、人員數據化！
      </h1>
      <Row className="mt-8">
        <Col span={24} className="flex justify-center">
          <div className="max-h-[800px]">
            <img className="max-h-[800px]" src={ERP_1} alt="" />
          </div>
          <div className="max-h-[800px] hidden md:block">
            <img className="max-h-[800px]" src={ERP_2} alt="" />
          </div>
        </Col>
        <Col span={24} className="flex justify-center md:hidden mt-8">
          <div className="max-h-[800px]">
            <img className="max-h-[800px]" src={ERP_2} alt="" />
          </div>
        </Col>
      </Row>
      <div className="flex justify-end self-end">
        <p className="text-base md:text-3xl text-left max-w-[800px] mt-8">
          由
          <span className="text-base md:text-3xl font-bold text-red-500">
            Tekla
          </span>
          的 BOM報表，匯入
          <span className="text-base md:text-3xl font-bold text-red-500">
            G
          </span>
          D-E
          <span className="text-base md:text-3xl font-bold text-red-500">
            R
          </span>
          P軟體再搭配廣達機械研發的QR鐵牌及手機App(掃描軟體)可以讓使用者清楚漲我個專案與人員進度管理
        </p>
      </div>
      <div className="flex justify-end self-end">
        <p
          className="text-2xl text-left font-bold max-w-[800px] mt-8"
          onClick={() => setOrderVisible(true)}
        >
          立即諮詢......
        </p>
      </div>
      <Row gutter={40} className="mt-16">
        <Col span={24} className="mb-8">
          <h1 className="text-3xl text-left">GD-ERP手機App功能(iPad也適用)</h1>
        </Col>
        <Col span={24} md={8} lg={4} className="mb-8">
          <img className="w-full max-w-[314px] m-auto" src={ERP掃描} alt="" />
          <div className="grid justify-items-center my-2">
            <img src={unknown} alt="" width={50} className="max-h-[50px]" />
          </div>
          <div className="border-2 rounded-2xl border-blue-500 max-w-[314px] m-auto">
            <h1 className="text-2xl font-bold text-blue-800">工項紀錄</h1>
          </div>
          <div className="max-w-[314px] m-auto">
            <p className="text-xl text-left">紀錄構件所有工項的人員名冊。</p>
          </div>
        </Col>
        <Col span={24} md={8} lg={4} className="mb-8">
          <img
            className="w-full max-w-[314px] m-auto"
            src={查詢PDF構件圖}
            alt=""
          />
          <div className="grid justify-items-center my-2">
            <img src={unknown1} alt="" width={50} className="max-h-[50px]" />
          </div>
          <div className="border-2 rounded-2xl border-blue-500 max-w-[314px] m-auto">
            <h1 className="text-2xl font-bold text-blue-800">PDF圖檔</h1>
          </div>
          <div className="max-w-[314px] m-auto">
            <p className="text-xl text-left">1. 構件PDF</p>
            <p className="text-xl text-left">2. 安裝PDF</p>
            <p className="text-xl text-left">3. 原設計圖PDF</p>
          </div>
        </Col>
        <Col span={24} md={8} lg={4} className="mb-8">
          <img
            className="w-full max-w-[314px] m-auto"
            src={即時監控}
            alt=""
          />
          <div className="grid justify-items-center my-2">
            <img src={unknown2} alt="" width={50} className="max-h-[50px]" />
          </div>
          <div className="border-2 rounded-2xl border-blue-500 max-w-[314px] m-auto">
            <h1 className="text-2xl font-bold text-blue-800">圖表分析</h1>
          </div>
          <div className="max-w-[314px] m-auto">
            <p className="text-xl text-left">專案即時進度圖表分析。</p>
          </div>
        </Col>
        <Col span={24} md={8} lg={4} className="mb-8">
          <img
            className="w-full max-w-[314px] m-auto"
            src={待出貨區列表}
            alt=""
          />
          <div className="grid justify-items-center my-2">
            <img src={unknown3} alt="" width={50} className="max-h-[50px]" />
          </div>
          <div className="border-2 rounded-2xl border-blue-500 max-w-[314px] m-auto">
            <h1 className="text-2xl font-bold text-blue-800">待出貨區</h1>
          </div>
          <div className="max-w-[314px] m-auto">
            <p className="text-xl text-left">
              即時查詢構件的置放位置，不浪費人力資源。
            </p>
          </div>
        </Col>
        <Col span={24} md={8} lg={4} className="mb-8">
          <img
            className="w-full max-w-[314px] m-auto"
            src={安裝資訊列表}
            alt=""
          />
          <div className="grid justify-items-center my-2">
            <img src={unknown4} alt="" width={50} className="max-h-[50px]" />
          </div>
          <div className="border-2 rounded-2xl border-blue-500 max-w-[314px] m-auto">
            <h1 className="text-2xl font-bold text-blue-800">安裝資訊</h1>
          </div>
          <div className="max-w-[314px] m-auto">
            <p className="text-xl text-left">
              顯示安裝位置及高程，長度與重量一併顯示。
            </p>
          </div>
        </Col>
        <Col span={24} md={8} lg={4} className="mb-8">
          <img className="w-full max-w-[314px] m-auto" src={地圖} alt="" />
          <div className="grid justify-items-center my-2">
            <img src={unknown5} alt="" width={50} className="max-h-[50px]" />
          </div>
          <div className="border-2 rounded-2xl border-blue-500 max-w-[314px] m-auto">
            <h1 className="text-2xl font-bold text-blue-800">工地導航</h1>
          </div>
          <div className="max-w-[314px] m-auto">
            <p className="text-xl text-left">讓人員輕鬆知道工地位置。</p>
          </div>
        </Col>
      </Row>
      <Footer />
    </div>
  );
};

export default ERPIntroduction;
