import React, { FC } from 'react';
import styles from './QRCodeIronMedal.module.scss';
import Footer from '../Footer/Footer';

import QRCode鐵牌標示 from '../../images/QRCode鐵牌標示.png';
import QRCode掃描 from '../../images/QRCode掃描.png';
import 烙鋼印法 from '../../images/烙(鋼)印法.png';
import 電銲寫字法 from '../../images/電銲寫字法.png';
import 號碼牌 from '../../images/號碼牌.png';
import 構件標記表格 from '../../images/構件標記表格.png';

import { Row, Col } from 'antd';

interface QRCodeIronMedalProps {}

const QRCodeIronMedal: FC<QRCodeIronMedalProps> = () => (
  <div
    className={`${styles.QRCodeIronMedal} mx-8`}
    data-testid="QRCodeIronMedal"
  >
    <Row align="middle">
      <Col span={24} lg={12} className="order-last lg:order-first">
        <img src={QRCode鐵牌標示} alt="QRCode鐵牌標示" />
      </Col>
      <Col span={24} lg={12}>
        <img src={QRCode掃描} alt="QRCode掃描" />
      </Col>
    </Row>
    <div className="bg-gray-300 rounded-lg h-[70px] grid items-center my-4">
      <h1 className="text-3xl font-bold">構件標記的重要性-1</h1>
    </div>
    <h1 className="text-left text-3xl text-blue-500 font-bold max-w-[1200px] m-auto">
      一、鋼構構件的標記方法(如下):
    </h1>
    <Row gutter={[0, 16]} className="max-w-[1200px] m-auto my-4">
      <Col span={24} lg={12}>
        <div className="grid sm:flex">
          <div className="max-w-[300px]">
            <img src={烙鋼印法} alt="烙(鋼)印法" className="w-full" />
          </div>
          <div className="text-left mt-2 ml-4 sm:mt-0">
            <span className="text-2xl font-bold">A.烙(鋼)印法</span>
          </div>
        </div>
      </Col>
      <Col span={24} lg={12}>
        <div className="grid sm:flex">
          <div className="max-w-[300px]">
            <img src={電銲寫字法} alt="電銲寫字法" className="w-full" />
          </div>
          <div className="text-left mt-2 ml-4 sm:mt-0">
            <span className="text-2xl font-bold">B.電焊寫字法</span>
            <p className="text-2xl">
              1. 成本太高(費時又耗工)且不美觀。 <br />
              2. 安裝找料時,經常性”鋼印面朝下”。
            </p>
          </div>
        </div>
      </Col>
    </Row>
    <h1 className="text-left text-3xl text-blue-500 font-bold my-4 max-w-[1200px] m-auto">
      烙印、電焊寫字的方式，現場尋料極為不便之情況,導致人員與吊車成本的增加!!
    </h1>
    <Row className="max-w-[1200px] m-auto">
      <Col span={24} lg={12}>
        <img src={號碼牌} alt="號碼牌" className="w-full max-w-[400px]" />
      </Col>
      <Col span={24} lg={12} className="text-left">
        <span className="text-2xl font-bold">C.號碼牌</span>
        <p className="text-2xl mt-4">
          成本固定
          <span className="text-2xl font-bold text-red-500">
            (費用不超過工程照價的0.001%)
          </span>
          ,可搭配安裝方向固定於構件上,不輕易脫落且
          <span className="text-2xl font-bold text-red-500">字深可達1.4mm</span>
          ,辨識度清楚!!絕不浪費客戶的任何成本!
          <p className="text-2xl text-blue-500 mt-8">
            現場安裝時,非常好辨識且完全不會浪費不必要的尋料等待時間!!重點是牌子固定於鋼梁(柱)的頭尾端與安裝方式對應且不會脫落!!
          </p>
        </p>
      </Col>
    </Row>
    <div className="bg-gray-300 rounded-lg h-[70px] grid items-center my-4">
      <h1 className="text-3xl font-bold">構件標記的重要性-2</h1>
    </div>
    <Row className="mb-4 max-w-[1200px] m-auto">
      <Col span={24} lg={12} className="text-left">
        <h1 className="text-left text-3xl text-blue-500 font-bold">
          二、標記的優點:
        </h1>
        <p className="text-2xl">
          1. 迅速找料 <br />
          2. 立即辨識安裝方向 <br />
          3. 可搭配進銷存報表
        </p>
        <h1 className="text-left text-3xl text-blue-500 font-bold">
          三、方法與成本分析比較表(設定條件如下)
        </h1>
        <p className="text-2xl">
          1. 工資為2500元/天,工時換算2500元/8小時/60分 <br />
          <span className="text-3xl font-bold text-red-500 ml-6">5.2元</span>
          /分鐘 <br />
          2. 構件編號：5個字體/支 <br />
          3. 構件數量：5支
        </p>
      </Col>
      <Col span={24} lg={12}>
        <img src={構件標記表格} alt="構件標記表格" />
      </Col>
    </Row>
    <Footer />
  </div>
);

export default QRCodeIronMedal;
