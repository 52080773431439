import React, { useState } from 'react';
import styles from './Header.module.scss';
import styled from 'styled-components';

import { Link } from 'react-router-dom';

const Fade = require('react-reveal/Fade');

const Header = () => {
  const [click, setClick] = useState(false);

  const handleMenuBar = () => setClick(!click);
  const handleMenuX = () => setClick(false);
  return (
    <Container data-testid="Header" className={styles.Header}>
      <Link to="/">
        <img
          src="/images/廣達機械LOGO（全）-01.png"
          alt="GUANDA-Logo"
          width="100"
        />
      </Link>
      <Menu className="text-white">
        <Link to="/introduction">公司簡介</Link>
        <Link to="/GD-STD">GD-STD</Link>
        <Link to="/AutomateProduction">自動化產線</Link>
        <Link to="/ERPIntroduction">ERP軟體</Link>
        <Link to="/QRCodeIronMedal">QRCode鐵牌</Link>
      </Menu>
      <RightMenu className="text-white">
        <a href="/ContactUs">聯絡我們</a>
        <i
          className="fas fa-bars text-white md:!hidden"
          onClick={handleMenuBar}
        ></i>
      </RightMenu>
      {click && (
        <MenuItems>
          <Fade right>
            <i className="fas fa-times" onClick={handleMenuX}></i>
            <Link to="/introduction">
              <li>公司簡介</li>
            </Link>
            <Link to="/GD-STD">
              <li>GD-STD</li>
            </Link>
            <Link to="/AutomateProduction">
              <li>自動化產線</li>
            </Link>
            <Link to="/ERPIntroduction">
              <li>ERP軟體</li>
            </Link>
            <Link to="/QRCodeIronMedal">
              <li>QRCode鐵牌</li>
            </Link>
          </Fade>
        </MenuItems>
      )}
    </Container>
  );
};

export default Header;

const Container = styled.div`
  min-height: 60px;
  width: 100vw;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;

const Menu = styled.div`
  display: flex;
  flex: 1;
  align-item: center;
  justify-content: center;
  a {
    font-weight: 600;
    text-transform: uppercase;
    padding: 5px 10px;
    flex-wrap: no-wrap;
  }

  a:hover {
    background-color: rgba(23, 26, 32, 0.07);
    border-radius: 20px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const RightMenu = styled.div`
  align-item: center;
  cursor: pointer;
  a {
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 10px;
    padding: 5px 10px;
  }

  a:hover {
    background-color: rgba(23, 26, 32, 0.07);
    border-radius: 20px;
  }

  @media (max-width: 360px) {
    a {
      display: none;
    }
  }
`;

const MenuItems = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  width: 300px;
  height: 100vh;
  list-style: none;
  padding: 20px;
  display: flex;
  flex-direction: column;
  text-align: start;
  z-index: 100;
  overflow: scroll;
  li {
    padding: 10px;
    margin-left: 20px;
  }
  a {
    font-weight: 600;
  }

  i {
    display: flex;
    align-item: right;
    justify-content: right;
    cursor: pointer;
  }

  li:hover {
    background-color: rgba(23, 26, 32, 0.07);
    border-radius: 20px;
  }
`;
