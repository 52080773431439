import React from 'react';
import styles from './Introduction.module.scss';
import Section from '../Section/Section';
import Footer from '../Footer/Footer';

import 廣達機械LOGO from '../../images/廣達機械LOGO（全）-03.png';
import 單LOGO from '../../images/單LOGO.png';
import GD商標 from '../../images/GD商標.png';
import 商標2 from '../../images/商標2.jpg';
import 商標3 from '../../images/商標3.jpg';
import 商標4 from '../../images/商標4.jpg';

import { Row, Col, Divider } from 'antd';

const Introduction = () => (
  <div
    className={`${styles.Introduction} w-full grid justify-center`}
    data-testid="Introduction"
  >
    <Row>
      <Col span={24} className="flex justify-center items-center">
        <img className="w-fit max-h-[100vh]" src={廣達機械LOGO} alt="" />
      </Col>
    </Row>
    <div className="grid justify-center">
      <div className="my-16 px-4 text-xl w-full max-w-screen-xl">
        <h1 className="text-4xl text-left font-bold">公司簡介</h1>
        <h2 className="text-2xl text-center font-bold">
          以創意科技為動力，讓產品更加入人性與便利
        </h2>
        <Row className="mt-8 mb-16">
          <Col span={24} md={4}>
            <div className="flex justify-center">
              <img src={單LOGO} alt="GUANDA" width={170}></img>
            </div>
          </Col>
          <Col span={24} md={20}>
            <p className="text-xl text-left mx-8 leading-9">
              廣達國際機械是為了解決鋼構加工需求而存在的。
              <br />
              廣是「廣思集益」意味著透過團隊經由反覆討論而得出滿足客戶需求的最佳方案，過程就像是廣收各方意見，再萃取出智慧。達是「達權知變」意指當團隊面對客戶各式各樣需求時，能不墨守成規，因地制宜地提出最適當方案。國際則是期許團隊從台灣出發，進而立足國際市場。
              <br />
              再者，秉持「以創意科技為動力，讓產品更加人性與便利」的產品開發設計理念，廣達國際團隊更期許自己能以創意科技為基底，為客戶打造出最人性化與便利化的鋼構加工設備產品。
            </p>
          </Col>
        </Row>
        <Divider orientation="left" className="!text-3xl !font-bold">
          商標
        </Divider>
        <Row align="middle" gutter={[16, 64]}>
          <Col span={12} className="grid justify-items-center items-end">
            <img
              src={GD商標}
              alt=""
              className="w-full md:h-auto max-w-[500px]"
            />
            <h1 className="text-2xl font-bold px-2 self-start">公司商標</h1>
          </Col>
          <Col span={12} className="grid justify-items-center items-end">
            <img
              src={商標2}
              alt=""
              className="w-full md:h-auto max-w-[500px]"
            />
            <h1 className="text-2xl font-bold px-2 self-start">公司商標</h1>
          </Col>
          <Col span={12} className="grid justify-items-center items-end">
            <img
              src={商標3}
              alt=""
              className="w-full md:h-auto max-w-[500px]"
            />
            <h1 className="text-2xl font-bold px-2 self-start">公司商標</h1>
          </Col>
          <Col span={12} className="grid justify-items-center items-end">
            <img
              src={商標4}
              alt=""
              className="w-full md:h-auto max-w-[500px]"
            />
            <h1 className="text-2xl font-bold px-2">公司商標</h1>
          </Col>
        </Row>
      </div>
    </div>
    <Footer />
  </div>
);

export default Introduction;
