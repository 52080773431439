import React, { FC } from 'react';
import styles from './OrderInfo.module.scss';

import QRCode from '../../images/QRCode.png';

interface OrderInfoProps {}

const OrderInfo: FC<OrderInfoProps> = () => (
  <div className={styles.OrderInfo} data-testid="OrderInfo">
    <div className="grid">
      <div className="justify-self-center">
        <img className="" src={QRCode} alt=""></img>
      </div>
      <div className="self-center mt-16">
        <p className="w-full text-center text-5xl font-bold">
          廣達國際機械 <br />
          <span className="w-full text-center text-4xl font-bold">
            04-23356118
          </span>
        </p>
      </div>
    </div>
  </div>
);

export default OrderInfo;
