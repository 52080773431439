import React from 'react';
import styles from './GD_STD.module.scss';
import OrderInfo from '../OrderInfo/OrderInfo';
import Footer from '../Footer/Footer';

import DSC01039 from '../../images/DSC01039.jpg';
import DSC08203 from '../../images/DSC08203.jpg';
import DSC08259 from '../../images/DSC08259.jpg';
import DSC01047 from '../../images/DSC01047.jpg';
import DSC01091 from '../../images/DSC01091.jpg';

import MQL油霧切削潤滑系統 from '../../images/MQL油霧切削潤滑系統.jpg';
import 硬質金屬鑽孔 from '../../images/硬質金屬鑽孔.jpg';

import _001_00_6_主機 from '../../images/_001-00-6-主機.png';

import 專利1 from '../../images/專利1.jpg';
import 專利2 from '../../images/專利2.jpg';
import 專利3 from '../../images/專利3.jpg';
import 專利4 from '../../images/專利4.jpg';
import 專利5 from '../../images/專利5.jpg';

import { Row, Col, Modal } from 'antd';

const Fade = require('react-reveal/Fade');

const GD_STD = () => {
  const [orderVisible, setOrderVisible] = React.useState(false);
  return (
    <div className={`${styles.GD_STD}`} data-testid="GD_STD">
      <Modal
        title=""
        centered
        visible={orderVisible}
        onOk={() => setOrderVisible(false)}
        onCancel={() => setOrderVisible(false)}
        width={500}
        footer={null}
      >
        <OrderInfo />
      </Modal>
      <div className={styles.snapContainer}>
        <Row className={styles.snapSection} style={{ height: '100vh' }}>
          <Col span={24} className="flex justify-center items-center">
            <img className="w-fit max-h-[100vh]" src={_001_00_6_主機} alt="" />
          </Col>
        </Row>
        <Row className={styles.snapSection} style={{ height: '100vh' }}>
          <Col
            span={24}
            lg={12}
            className="lg:h-[70vh] h-[35vh]"
            style={{
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundImage: `url(${DSC01039})`,
            }}
          ></Col>
          <Col
            span={24}
            lg={12}
            className="lg:h-[70vh] h-[35vh]"
            style={{
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundImage: `url(${DSC08203})`,
            }}
          ></Col>
          <Col
            span={24}
            style={{
              height: '30vh',
            }}
          >
            <div className="grid justify-items-stretch items-stretch h-full">
              <Fade bottom>
                <div className="text-left mt-2 sm:mt-8 sm:ml-16 px-4 sm:pb-8 grid sm:grid-rows-3 grid-flow-row sm:grid-flow-col sm:gap-4">
                  <div className="text-2xl sm:text-3xl text-left sm:text-right sm:row-span-3">
                    <h1 className="font-bold">左右軸換刀裝置</h1>
                    <p className="text-base sm:text-xl sm:hidden">
                      左右側主軸的刀具都搭配全新雙向各4個
                      定位換刀系統,可以容納各式各樣的刀具
                      種類(可以搭配手機APP使用)。
                    </p>
                  </div>
                  <p className="text-xl hidden sm:block sm:row-span-2">
                    左右側主軸的刀具都搭配全新雙向各4個
                    定位換刀系統,可以容納各式各樣的刀具
                    種類(可以搭配手機APP使用)。
                  </p>
                  <div className="justify-self-end self-end">
                    <p
                      className="text-xl sm:text-2xl cursor-pointer font-bold"
                      onClick={() => setOrderVisible(true)}
                    >
                      立刻訂購...
                    </p>
                  </div>
                </div>
              </Fade>
            </div>
          </Col>
        </Row>
        <Row
          className={styles.snapSection}
          align="middle"
          style={{ height: '100vh' }}
        >
          <Col
            span={24}
            lg={6}
            className="lg:h-full lg:order-first h-[30vh] order-last"
          >
            <div className="grid justify-items-stretch items-stretch h-full pt-2 sm:pt-8 lg:pt-28 px-4 sm:pb-8">
              <Fade bottom>
                <div className="text-left">
                  <h1 className="text-2xl sm:text-3xl font-bold">
                    上軸換刀裝置
                  </h1>
                  <p className="text-base sm:text-xl">
                    自動化換刀裝置通過數控系統管理,並
                    在上主軸配有5個定位換刀系統(可以 搭配手機APP使用)。
                  </p>
                </div>
                <div className="justify-self-end self-end">
                  <p
                    className="text-xl sm:text-2xl cursor-pointer font-bold"
                    onClick={() => setOrderVisible(true)}
                  >
                    立刻訂購...
                  </p>
                </div>
              </Fade>
            </div>
          </Col>
          <Col
            span={24}
            lg={18}
            className="lg:h-full h-[70vh]"
            style={{
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundImage: `url(${DSC08259})`,
            }}
          ></Col>
        </Row>
        <Row
          className={styles.snapSection}
          align="middle"
          style={{ height: '100vh' }}
        >
          <Col
            span={24}
            lg={18}
            className="lg:h-full h-[70vh]"
            style={{
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundImage: `url(${DSC01047})`,
            }}
          ></Col>
          <Col span={24} lg={6} className="lg:h-full h-[30vh] order-last">
            <div className="grid justify-items-stretch items-stretch h-full pt-2 sm:pt-8 lg:pt-28 px-4 sm:pb-8">
              <Fade bottom>
                <div className="text-left">
                  <h1 className="text-2xl sm:text-3xl font-bold">
                    擴展輔助Y軸向移動
                  </h1>
                  <p className="text-sm sm:text-xl">
                    每個主軸上可用的Y軸行程,延長至
                    300mm,通過將材料保持在適當位置 來執行加工操作。
                    三個主軸軸向皆可獨立加工,並轉化為
                    更大的靈活性和無與倫比的生產力。
                  </p>
                </div>
                <div className="justify-self-end self-end">
                  <p
                    className="text-xl sm:text-2xl cursor-pointer font-bold"
                    onClick={() => setOrderVisible(true)}
                  >
                    立刻訂購...
                  </p>
                </div>
              </Fade>
            </div>
          </Col>
        </Row>
        <Row
          className={styles.snapSection}
          align="middle"
          style={{ height: '100vh' }}
        >
          <Col
            span={24}
            lg={6}
            className="lg:h-full lg:order-first h-[30vh] order-last"
          >
            <div className="grid justify-items-stretch items-stretch h-full pt-2 sm:pt-8 lg:pt-28 px-4">
              <Fade bottom>
                <div className="text-left">
                  <h1 className="text-2xl sm:text-3xl font-bold">
                    單一CNC控制單元
                  </h1>
                  <p className="text-base sm:text-2xl">
                    當鋼印機或入口/出口橫移自動料 架結合使用時,無須添加額外的
                    CNC控制單元
                  </p>
                </div>
                <div className="justify-self-end self-end">
                  <p
                    className="text-xl sm:text-2xl cursor-pointer font-bold"
                    onClick={() => setOrderVisible(true)}
                  >
                    立刻訂購...
                  </p>
                </div>
              </Fade>
            </div>
          </Col>
          <Col
            span={24}
            lg={18}
            className="lg:h-full h-[70vh]"
            style={{
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundImage: `url(${DSC01091})`,
            }}
          ></Col>
        </Row>

        <Row
          className={styles.snapSection}
          align="middle"
          style={{ height: '100vh' }}
        >
          <Col span={24} className="w-full h-full">
            <div className="grid justify-items-stretch items-stretch w-full h-full">
              <Row>
                <Col span={24} md={12} className="grid">
                  <div className="flex items-end justify-center md:justify-end">
                    <div className="mt-16 md:ml-8 md:mt-8 flex items-center">
                      <div>
                        <Fade bottom>
                          <h1 className="text-3xl font-bold">
                            MQL油霧切削潤滑系統
                          </h1>
                          <p className="text-xl">
                            中心出油、耗油低,鑽孔及在深孔加工有良好的表現。
                          </p>
                        </Fade>
                      </div>
                      <img
                        className="hidden md:block"
                        src={MQL油霧切削潤滑系統}
                        alt=""
                      ></img>
                    </div>
                  </div>
                  <div className="flex md:grid justify-center">
                    <div>
                      <img src={MQL油霧切削潤滑系統} alt=""></img>
                    </div>
                    <div>
                      <img
                        className="block md:hidden"
                        src="/images/MQL油霧切削潤滑系統.jpg"
                        alt=""
                      ></img>
                    </div>
                  </div>
                </Col>
                <Col span={24} md={12} className="grid">
                  <div className="flex items-end justify-center order-last md:order-first">
                    <div>
                      <img src={硬質金屬鑽孔} alt=""></img>
                    </div>
                    <div>
                      <img
                        className="block md:hidden"
                        src={硬質金屬鑽孔}
                        alt=""
                      ></img>
                    </div>
                  </div>
                  <div className="mr-8 mt-8 flex items-end justify-center md:justify-center md:items-start">
                    <img
                      className="hidden md:block"
                      src={硬質金屬鑽孔}
                      alt=""
                    ></img>
                    <div className="flex items-center">
                      <div>
                        <Fade bottom>
                          <h1 className="text-3xl font-bold">硬質合金鑽孔</h1>
                          <p className="text-xl">
                            憑藉高轉速,可實現主軸硬質合金 刀具的最大效率。
                          </p>
                        </Fade>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row
          className={styles.snapSection}
          align="middle"
          style={{ height: '100vh' }}
        >
          <Col
            span={24}
            lg={12}
            className="h-full grid justify-items-center items-center"
          >
            <img className="max-h-[100vh]" src={_001_00_6_主機} alt="" />
          </Col>
          <Col
            span={24}
            lg={12}
            className="h-full hidden lg:grid justify-items-center flex items-center"
          >
            <div className="text-left text-lg">
              <table
                style={{
                  borderCollapse: 'separate',
                  borderSpacing: '20px 0px',
                }}
              >
                <tr
                  className="text-center"
                  style={{ backgroundColor: '#aa0007' }}
                >
                  <th colSpan={2} className="text-white">
                    機械規格
                  </th>
                </tr>
                <tr style={{ backgroundColor: '#1e334c' }}>
                  <th colSpan={2} className="text-white">
                    允許的工作尺寸
                  </th>
                </tr>
                <tr>
                  <td className="min-w-[300px]">腹板高度</td>
                  <td>75~500mm</td>
                </tr>
                <tr>
                  <td>翼板寬度</td>
                  <td>150~1050mm</td>
                </tr>
                <tr>
                  <td>最短加工長度</td>
                  <td>2400mm</td>
                </tr>
                <tr>
                  <td>最大加工長度</td>
                  <td>99999mm</td>
                </tr>
                <tr style={{ backgroundColor: '#55424c' }}>
                  <th colSpan={2} className="text-white">
                    加工性能
                  </th>
                </tr>
                <tr>
                  <td>垂直鑽孔頭</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>水平鑽孔頭</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>每一垂直鑽孔頭的主軸</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>每一水平鑚孔頭的主軸</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>輔助軸最大行程</td>
                  <td>300mm</td>
                </tr>
                <tr>
                  <td>最大孔徑</td>
                  <td>Ø40</td>
                </tr>
                <tr>
                  <td>最大板厚</td>
                  <td>80mm</td>
                </tr>
                <tr>
                  <td>主軸最大功率</td>
                  <td>15kw and 11kw</td>
                </tr>
                <tr>
                  <td>刀把規格</td>
                  <td>BT40</td>
                </tr>
                <tr>
                  <td>主軸轉速</td>
                  <td>180~400RPM</td>
                </tr>
                <tr>
                  <td>鑚孔軸進給速</td>
                  <td>40~1000mm/min</td>
                </tr>
                <tr>
                  <td>鑽孔軸軸向移動速度</td>
                  <td>24M/min</td>
                </tr>
                <tr style={{ backgroundColor: '#0f3d30' }}>
                  <th colSpan={2} className="text-white">
                    機台尺寸
                  </th>
                </tr>
                <tr>
                  <td>長</td>
                  <td>5450mm(不含排屑機)</td>
                </tr>
                <tr>
                  <td>寬</td>
                  <td>2000mm(不含凸出附件)</td>
                </tr>
                <tr>
                  <td>高</td>
                  <td>2000mm(不含凸出附件)</td>
                </tr>
                <tr>
                  <td>機台重量</td>
                  <td>14.5ton</td>
                </tr>
              </table>
            </div>
          </Col>
        </Row>
        <Row
          className={`${styles.snapSection} flex lg:hidden`}
          align="middle"
          style={{ height: '100vh' }}
        >
          <Col
            span={24}
            className="h-full grid justify-items-center flex items-center"
          >
            <div className="text-left text-base">
              <table
                style={{
                  borderCollapse: 'separate',
                  borderSpacing: '20px 0px',
                }}
              >
                <tr
                  className="text-center"
                  style={{ backgroundColor: '#aa0007' }}
                >
                  <th colSpan={2} className="text-white">
                    機械規格
                  </th>
                </tr>
                <tr style={{ backgroundColor: '#1e334c' }}>
                  <th colSpan={2} className="text-white">
                    允許的工作尺寸
                  </th>
                </tr>
                <tr>
                  <td>腹板高度</td>
                  <td>75~500mm</td>
                </tr>
                <tr>
                  <td>翼板寬度</td>
                  <td>150~1050mm</td>
                </tr>
                <tr>
                  <td>最短加工長度</td>
                  <td>2400mm</td>
                </tr>
                <tr>
                  <td>最大加工長度</td>
                  <td>99999mm</td>
                </tr>
                <tr style={{ backgroundColor: '#55424c' }}>
                  <th colSpan={2} className="text-white">
                    加工性能
                  </th>
                </tr>
                <tr>
                  <td>垂直鑽孔頭</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>水平鑽孔頭</td>
                  <td>2</td>
                </tr>
                <tr>
                  <td>每一垂直鑽孔頭的主軸</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>每一水平鑚孔頭的主軸</td>
                  <td>1</td>
                </tr>
                <tr>
                  <td>輔助軸最大行程</td>
                  <td>300mm</td>
                </tr>
                <tr>
                  <td>最大孔徑</td>
                  <td>Ø40</td>
                </tr>
                <tr>
                  <td>最大板厚</td>
                  <td>80mm</td>
                </tr>
                <tr>
                  <td>主軸最大功率</td>
                  <td>15kw and 11kw</td>
                </tr>
                <tr>
                  <td>刀把規格</td>
                  <td>BT40</td>
                </tr>
                <tr>
                  <td>主軸轉速</td>
                  <td>180~400RPM</td>
                </tr>
                <tr>
                  <td>鑚孔軸進給速</td>
                  <td>40~1000mm/min</td>
                </tr>
                <tr>
                  <td>鑽孔軸軸向移動速度</td>
                  <td>24M/min</td>
                </tr>
                <tr style={{ backgroundColor: '#0f3d30' }}>
                  <th colSpan={2} className="text-white">
                    機台尺寸
                  </th>
                </tr>
                <tr>
                  <td>長</td>
                  <td>5450mm(不含排屑機)</td>
                </tr>
                <tr>
                  <td>寬</td>
                  <td>2000mm(不含凸出附件)</td>
                </tr>
                <tr>
                  <td>高</td>
                  <td>2000mm(不含凸出附件)</td>
                </tr>
                <tr>
                  <td>機台重量</td>
                  <td>14.5ton</td>
                </tr>
              </table>
            </div>
          </Col>
        </Row>
        <Row
          className={styles.snapSection}
          align="middle"
          style={{ height: '100vh' }}
        >
          <Col span={24}>
            <div className="grid justify-items-center m-auto text-left">
              <Row className="justify-center lg:min-w-[1000px] mx-4">
                <div>
                  <h1 className="text-3xl font-bold text-center md:w-full md:text-left mb-4 mx-4">
                    S<span className="text-3xl text-red-500">T</span>
                    D-105(11項優勢)
                  </h1>
                  <div className="hidden md:flex">
                    <ul className="mx-4">
                      <li className="text-2xl font-medium flex">
                        <span className="bg-red-800 rounded-full w-[28px] h-[28px] flex justify-center text-white text-xl mr-2">
                          1
                        </span>
                        TEKLA 無縫接軌移轉
                      </li>
                      <li className="text-lg ml-[36px]">
                        (不只轉檔、還有TEKLA的報表相關構件資料)。
                      </li>
                      <li className="text-2xl font-medium flex mt-2">
                        <span className="bg-red-800 rounded-full w-[28px] h-[28px] flex justify-center text-white text-xl mr-2">
                          2
                        </span>
                        自動可參數式排版及金額核算。
                      </li>
                      <li className="text-2xl font-medium flex mt-2">
                        <span className="bg-red-800 rounded-full w-[28px] h-[28px] flex justify-center text-white text-xl mr-2">
                          3
                        </span>
                        全自動化加工
                      </li>
                      <li className="text-lg ml-[36px]">
                        (自動送料、自動進料、自動加工及換刀、自動出料)。
                      </li>
                      <li className="text-2xl font-medium flex mt-2">
                        <span className="bg-red-800 rounded-full w-[28px] h-[28px] flex justify-center text-white text-xl mr-2">
                          4
                        </span>
                        採用鎢化鋼高硬度鑽頭
                      </li>
                      <li className="text-lg ml-[36px]">
                        (最速可達:9mm/s進給量)
                      </li>
                      <li className="text-2xl font-medium flex mt-2">
                        <span className="bg-red-800 rounded-full w-[28px] h-[28px] flex justify-center text-white text-xl mr-2">
                          5
                        </span>
                        採用MQL切削油系統
                      </li>
                      <li className="text-lg ml-[36px]">
                        (提高數倍潤滑效果且環保無毒)。
                      </li>
                      <li className="text-2xl font-medium flex mt-2">
                        <span className="bg-red-800 rounded-full w-[28px] h-[28px] flex justify-center text-white text-xl mr-2">
                          6
                        </span>
                        手機APP
                      </li>
                      <li className="text-lg ml-[36px]">
                        (可無線操作機台及周邊料架設備包含監控加工數據)。
                      </li>
                    </ul>
                    <ul className="mx-4">
                      <li className="text-2xl font-medium flex mt-2">
                        <span className="bg-red-800 rounded-full w-[28px] h-[28px] flex justify-center text-white text-xl mr-2">
                          7
                        </span>
                        19吋多點式觸控螢幕
                      </li>
                      <li className="text-lg ml-[36px]">
                        (全中文化界面,也包含多國語言)
                      </li>
                      <li className="text-2xl font-medium flex mt-2">
                        <span className="bg-red-800 rounded-full w-[28px] h-[28px] flex justify-center text-white text-xl mr-2">
                          8
                        </span>
                        3D模型及3D影像追隨
                      </li>
                      <li className="text-lg ml-[36px]">
                        (辦公室軟體也可監控機台動態)
                      </li>
                      <li className="text-2xl font-medium flex mt-2">
                        <span className="bg-red-800 rounded-full w-[28px] h-[28px] flex justify-center text-white text-xl mr-2">
                          9
                        </span>
                        4項發明專利
                      </li>
                      <li className="text-lg ml-[36px]">
                        (直覺式排版、切割線、孔群設定)
                      </li>
                      <li className="text-2xl font-medium flex mt-2">
                        <span className="bg-red-800 rounded-full w-[28px] h-[28px] flex justify-center text-white text-xl mr-2">
                          10
                        </span>
                        現場操作人員教育訓練簡單快速。
                      </li>
                      <li className="text-2xl font-medium flex mt-2">
                        <span className="bg-red-800 rounded-full w-[28px] h-[28px] flex justify-center text-white text-xl mr-2">
                          11
                        </span>
                        整機設備:MIT生產,在地維修。
                      </li>
                    </ul>
                  </div>
                </div>
                <Col span={24} className="flex md:hidden">
                  <ul>
                    <li className="text-2xl font-medium flex">
                      <span className="bg-red-800 rounded-full w-[28px] h-[28px] flex justify-center text-white text-xl mr-2">
                        1
                      </span>
                      TEKLA 無縫接軌移轉
                    </li>
                    <li className="text-lg ml-[36px]">
                      (不只轉檔、還有TEKLA的報表相關構件資料)。
                    </li>
                    <li className="text-2xl font-medium flex mt-2">
                      <span className="bg-red-800 rounded-full w-[28px] h-[28px] flex justify-center text-white text-xl mr-2">
                        2
                      </span>
                      自動可參數式排版及金額核算。
                    </li>
                    <li className="text-2xl font-medium flex mt-2">
                      <span className="bg-red-800 rounded-full w-[28px] h-[28px] flex justify-center text-white text-xl mr-2">
                        3
                      </span>
                      全自動化加工
                    </li>
                    <li className="text-lg ml-[36px]">
                      (自動送料、自動進料、自動加工及換刀、自動出料)。
                    </li>
                    <li className="text-2xl font-medium flex mt-2">
                      <span className="bg-red-800 rounded-full w-[28px] h-[28px] flex justify-center text-white text-xl mr-2">
                        4
                      </span>
                      採用鎢化鋼高硬度鑽頭
                    </li>
                    <li className="text-lg ml-[36px]">
                      (最速可達:9mm/s進給量)
                    </li>
                    <li className="text-2xl font-medium flex mt-2">
                      <span className="bg-red-800 rounded-full w-[28px] h-[28px] flex justify-center text-white text-xl mr-2">
                        5
                      </span>
                      採用MQL切削油系統
                    </li>
                    <li className="text-lg ml-[36px]">
                      (提高數倍潤滑效果且環保無毒)。
                    </li>
                    <li className="text-2xl font-medium flex mt-2">
                      <span className="bg-red-800 rounded-full w-[28px] h-[28px] flex justify-center text-white text-xl mr-2">
                        6
                      </span>
                      手機APP
                    </li>
                    <li className="text-lg ml-[36px]">
                      (可無線操作機台及周邊料架設備包含監控加工數據)。
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row
          className={`${styles.snapSection} flex md:hidden`}
          align="middle"
          style={{ height: '100vh' }}
        >
          <Col span={24}>
            <div className="grid justify-items-center m-auto text-left">
              <h1 className="text-3xl font-bold text-center lg:min-w-[1000px] lg:text-left">
                S<span className="text-3xl text-red-500">T</span>D-105(11項優勢)
              </h1>
              <Row className="lg:min-w-[1000px] mx-4 mt-4">
                <Col span={24}>
                  <ul>
                    <li className="text-2xl font-medium flex mt-2">
                      <span className="bg-red-800 rounded-full w-[28px] h-[28px] flex justify-center text-white text-xl mr-2">
                        7
                      </span>
                      19吋多點式觸控螢幕
                    </li>
                    <li className="text-lg ml-[36px]">
                      (全中文化界面,也包含多國語言)
                    </li>
                    <li className="text-2xl font-medium flex mt-2">
                      <span className="bg-red-800 rounded-full w-[28px] h-[28px] flex justify-center text-white text-xl mr-2">
                        8
                      </span>
                      3D模型及3D影像追隨
                    </li>
                    <li className="text-lg ml-[36px]">
                      (辦公室軟體也可監控機台動態)
                    </li>
                    <li className="text-2xl font-medium flex mt-2">
                      <span className="bg-red-800 rounded-full w-[28px] h-[28px] flex justify-center text-white text-xl mr-2">
                        9
                      </span>
                      4項發明專利
                    </li>
                    <li className="text-lg ml-[36px]">
                      (直覺式排版、切割線、孔群設定)
                    </li>
                    <li className="text-2xl font-medium flex mt-2">
                      <span className="bg-red-800 rounded-full w-[28px] h-[28px] flex justify-center text-white text-xl mr-2">
                        10
                      </span>
                      現場操作人員教育訓練簡單快速。
                    </li>
                    <li className="text-2xl font-medium flex mt-2">
                      <span className="bg-red-800 rounded-full w-[28px] h-[28px] flex justify-center text-white text-xl mr-2">
                        11
                      </span>
                      整機設備:MIT生產,在地維修。
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row
          className={`${styles.snapSection} px-4`}
          align="middle"
          style={{ height: '100vh' }}
        >
          <Col
            span={12}
            md={8}
            className="grid justify-items-center items-center"
          >
            <div className="max-w-[550px]">
              <img
                src={專利1}
                alt=""
                className="h-[30vh] md:max-h-[1000px] md:h-auto m-auto"
              />
              <h1 className="text-2xl font-bold px-2">
                鋼構建材之翻轉加工規劃方法
              </h1>
            </div>
          </Col>
          <Col
            span={12}
            md={8}
            className="grid justify-items-center items-center"
          >
            <div className="max-w-[550px]">
              <img
                src={專利2}
                alt=""
                className="h-[30vh] md:max-h-[1000px] md:h-auto m-auto"
              />
              <h1 className="text-2xl font-bold px-2">
                鋼構建材之鑽孔加工設計方法
              </h1>
            </div>
          </Col>
          <Col
            span={12}
            md={8}
            className="grid justify-items-center items-center"
          >
            <div className="max-w-[550px]">
              <img
                src={專利3}
                alt=""
                className="h-[30vh] md:max-h-[1000px] md:h-auto m-auto"
              />
              <h1 className="text-2xl font-bold px-2">
                顯示螢幕之圖形化使用者介面
              </h1>
            </div>
          </Col>
          <Col
            span={12}
            md={1}
            className="grid md:hidden justify-items-center items-center"
          >
            <div className="max-w-[550px]">
              <img src={專利4} alt="" className="h-[30vh] md:h-auto m-auto" />
              <h1 className="text-2xl font-bold px-2">
                鋼構之截角加工規劃方法
              </h1>
            </div>
          </Col>
        </Row>
        <Row
          className={styles.snapSection}
          align="middle"
          style={{ height: '90vh' }}
          gutter={32}
        >
          <Col
            span={12}
            md={{ span: 8, offset: 4 }}
            className="hidden md:grid justify-items-center items-center"
          >
            <div className="max-w-[550px]">
              <img
                src={專利4}
                alt=""
                className="h-[30vh] md:max-h-[1000px] md:h-auto m-auto"
              />
              <h1 className="text-2xl font-bold px-2">
                鋼構之截角加工規劃方法
              </h1>
            </div>
          </Col>
          <Col
            span={12}
            md={{ span: 8, offset: 0 }}
            offset={6}
            className="grid justify-items-center items-center"
          >
            <div className="max-w-[550px]">
              <img
                src={專利5}
                alt=""
                className="h-[30vh] md:max-h-[1000px] md:h-auto m-auto"
              />
              <h1 className="text-2xl font-bold px-2">
                鋼構建材之加工設計方法
              </h1>
            </div>
          </Col>
        </Row>
        <Footer />
      </div>
    </div>
  );
};

export default GD_STD;
