import React from 'react';
import styles from './Home.module.scss';
import styled from 'styled-components';
import Section from '../Section/Section';
import Footer from '../Footer/Footer';

import LINE_ALBUM_220223 from '../../images/LINE_ALBUM_220223.jpg';
import LINE_ALBUM_220222 from '../../images/LINE_ALBUM_220222.jpg';
import ERP_1 from '../../images/ERP_1.png';
import 專案分析 from '../../images/專案分析.png';
import QRCode鐵牌標示 from '../../images/QRCode鐵牌標示.png';
import S__12181508 from '../../images/S__12181508.jpg';
import S__12181510 from '../../images/S__12181510.jpg';
import S__12181511 from '../../images/S__12181511.jpg';
import S__12181512 from '../../images/S__12181512.jpg';

import S__49258796 from '../../images/S__49258796.jpg';
import S__49258804 from '../../images/S__49258804.jpg';
import S__49258805 from '../../images/S__49258805.jpg';

import { Link } from 'react-router-dom';

import { Row, Col } from 'antd';

const Fade = require('react-reveal/Fade');

const Home = () => {
  return (
    <Container className={styles.snapContainer} data-testid="Home">
      <div className={styles.snapSection}>
        <Section
          model="以創意科技為動力，讓產品更加入人性與便利"
          backgroundImg={S__49258796}
          textPosition="bottom"
          titleFontSize="3rem"
          titleFontWeight="700"
          titleFontColor="white"
          descriptionFontSize="2rem"
          descriptionFontColor="white"
          downArrow={true}
        />
      </div>
      <div className={styles.snapSection}>
        <Section
          model="GD-STD"
          backgroundImg={S__49258804}
          leftBtn="了解更多內容......"
          leftBtnLink="/GD-STD"
          titleFontSize="3rem"
          titleFontWeight="700"
          titleFontColor="white"
          descriptionFontSize="2rem"
          descriptionFontColor="white"
          downArrow={true}
        />
      </div>
      <div className={styles.snapSection}>
        <Section
          model="自動化產線"
          backgroundImg={S__49258805}
          leftBtn="了解更多內容......"
          leftBtnLink="/AutomateProduction"
          titleFontSize="3rem"
          titleFontWeight="700"
          titleFontColor="white"
          descriptionFontSize="2rem"
          descriptionFontColor="white"
          downArrow={true}
        />
      </div>
      <Row
        className={`hidden md:flex justify-center ${styles.snapSection}`}
        align="middle"
        style={{ height: '100vh' }}
      >
        <div className="w-full flex flex-col justify-between absolute h-screen px-4">
          <Fade bottom>
            <TopItemText>
              <h1
                className="text-5xl"
                style={{ fontSize: '3rem', fontWeight: '700' }}
              >
                ERP軟體
              </h1>
            </TopItemText>
          </Fade>
          <div>
            <Buttons>
              <Fade bottom>
                <ButtonGroup>
                  <Link to="/ERPIntroduction">
                    <LeftButton>了解更多內容......</LeftButton>
                  </Link>
                </ButtonGroup>
              </Fade>
            </Buttons>
          </div>
        </div>
        <Col
          span={24}
          className="flex justify-center max-w-[2000px] z-[-1] px-4 gap-4"
        >
          <div className="max-h-[40vh]">
            <img className="max-h-[40vh]" src={專案分析} alt="" />
          </div>
          <div className="max-h-[40vh]">
            <img className="max-h-[40vh]" src={ERP_1} alt="" />
          </div>
        </Col>
      </Row>

      {
        // ==============手機版==============
      }

      <Row
        className={`flex md:hidden ${styles.snapSection}`}
        align="middle"
        style={{ height: '100vh' }}
      >
        <div className="w-full flex flex-col justify-between absolute h-screen px-4">
          <Fade bottom>
            <TopItemText>
              <h1
                className="text-5xl"
                style={{ fontSize: '3rem', fontWeight: '700' }}
              >
                ERP軟體
              </h1>
            </TopItemText>
          </Fade>
          <div>
            <Buttons>
              <Fade bottom>
                <ButtonGroup>
                  <Link to="/ERPIntroduction">
                    <LeftButton>了解更多內容......</LeftButton>
                  </Link>
                </ButtonGroup>
              </Fade>

              <div className="grid justify-items-center">
                <DownArrow src="/images/down-arrow.svg" />
              </div>
            </Buttons>
          </div>
        </div>
        <Col span={24} className="flex justify-center z-[-1] px-4">
          <div className="max-h-screen max-w-[600px]">
            <img className="max-h-screen" src={ERP_1} alt="" />
          </div>
        </Col>
      </Row>

      <Row
        className={`flex md:hidden ${styles.snapSection}`}
        align="middle"
        style={{ height: '100vh' }}
      >
        <Col span={24} className="flex justify-center z-[-1] px-4">
          <div className="max-h-screen">
            <img className="max-h-screen" src={專案分析} alt="" />
          </div>
        </Col>
      </Row>

      {
        // ==============手機版==============
      }

      <Row
        className={`hidden md:flex ${styles.snapSection}`}
        align="middle"
        style={{ height: '100vh' }}
      >
        <div className="w-full flex flex-col justify-between absolute h-screen px-4">
          <Fade bottom>
            <TopItemText>
              <h1
                className="text-5xl"
                style={{ fontSize: '3rem', fontWeight: '700' }}
              >
                QRCode 鐵牌
              </h1>
            </TopItemText>
          </Fade>
          <div>
            <Buttons>
              <Fade bottom>
                <ButtonGroupNoMargin>
                  <Link to="/QRCodeIronMedal">
                    <LeftButton>了解更多內容......</LeftButton>
                  </Link>
                </ButtonGroupNoMargin>
              </Fade>
            </Buttons>
            <Footer />
          </div>
        </div>
        <Col
          span={24}
          className="grid grid-rows-2 grid-cols-3 grid-flow-col gap-4 max-h-screen z-[-1] px-4"
        >
          <div className="row-span-2 col-span-1 grid items-center justify-end">
            <div className="max-h-screen max-w-[490px]">
              <img className="max-h-[40vh]" src={ERP_1} alt="" />
            </div>
          </div>
          <div className="flex flex-row row-span-1 col-span-3 justify-center">
            <div className="grid grid-cols-3 items-center gap-4">
              <div className="col-span-1 max-w-[335px]">
                <img className="max-h-[20vh]" src={S__12181512} alt="" />
              </div>
              <div className="col-span-1 max-w-[335px]">
                <img className="max-h-[20vh]" src={S__12181511} alt="" />
              </div>
              <div className="col-span-1 max-w-[335px]">
                <img className="max-h-[20vh]" src={S__12181510} alt="" />
              </div>
            </div>
          </div>
          <div className="flex flex-row row-span-1 col-span-3 justify-center">
            <div className="grid grid-cols-3 items-center gap-4">
              <div className="col-span-2 max-w-[670px] max-h-[20vh]">
                <img className="max-h-[20vh]" src={QRCode鐵牌標示} alt="" />
              </div>
              <div className="col-span-1 max-w-[335px]">
                <img className="max-h-[20vh]" src={S__12181508} alt="" />
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {
        // ==============手機版==============
      }

      <Row
        className={`flex md:hidden ${styles.snapSection}`}
        align="middle"
        style={{ height: '100vh' }}
      >
        <div className="w-full flex flex-col justify-between absolute h-screen px-4">
          <Fade bottom>
            <TopItemText>
              <h1
                className="text-5xl"
                style={{ fontSize: '3rem', fontWeight: '700' }}
              >
                QRCode 鐵牌
              </h1>
            </TopItemText>
          </Fade>
          <div>
            <Buttons>
              <Fade bottom>
                <ButtonGroup>
                  <Link to="/QRCodeIronMedal">
                    <LeftButton>了解更多內容......</LeftButton>
                  </Link>
                </ButtonGroup>
              </Fade>

              <div className="grid justify-items-center">
                <DownArrow src="/images/down-arrow.svg" />
              </div>
            </Buttons>
          </div>
        </div>
        <Col span={24} className="flex justify-center z-[-1]">
          <div className="max-h-screen max-w-[600px]">
            <img className="max-h-screen" src={ERP_1} alt="" />
          </div>
        </Col>
      </Row>
      <Row
        className={`flex md:hidden ${styles.snapSection}`}
        align="middle"
        style={{ height: '100vh' }}
      >
        <Col
          span={24}
          className="grid grid-rows-3 grid-cols-2 gap-2 max-h-screen justify-center z-[-1] pt-[80px] px-4"
        >
          <div className="row-span-1 col-span-2 max-w-[670px] flex justify-center">
            <img
              className="max-h-full"
              src={QRCode鐵牌標示}
              alt="QRCode鐵牌標示"
            />
          </div>
          <div className="row-span-1 col-span-1 flex justify-end items-end">
            <img className="max-h-full" src={S__12181510} alt="" />
          </div>
          <div className="col-span-1 flex justify-start items-end">
            <img className="max-h-full" src={S__12181508} alt="" />
          </div>
          <div className="row-span-1 col-span-1 flex justify-end">
            <img className="max-h-full" src={S__12181512} alt="" />
          </div>
          <div className="col-span-1 flex justify-start">
            <img className="max-h-full" src={S__12181511} alt="" />
          </div>
          <div className="col-span-2">
            <Footer />
          </div>
        </Col>
      </Row>

      {
        // ==============手機版==============
      }
    </Container>
  );
};

export default Home;

const Container = styled.div`
  height: 100vh;
`;

const TopItemText = styled.div`
  z-idex: -1;
  padding-top: 15vh;
  text-align: center;
`;
const ButtonGroup = styled.div`
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const ButtonGroupNoMargin = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const LeftButton = styled.div`
  background-color: rgba(23, 26, 32, 0.6);
  height: 40px;
  width: 256px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  opacity: 0.85;
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;
  margin: 8px;
`;

const DownArrow = styled.img`
  margin-top: 20px;
  opacity: 0.65;
  height: 40px;
  animation: animateDown infinite 1.5s;
  overflow-x: hidden;
`;

const Buttons = styled.div`
  align-items: center;
  text-align: center;
  justify-content: center;
`;
